import * as React from "react"
import { ThemeCss } from "gatsby-interface"
import { useAnimate } from "@modules/ui/hooks/useAnimate"

import decor01 from "@modules/user/assets/decor01.svg"
import decor02 from "@modules/user/assets/decor02.svg"

export type LayoutProps = {
  children: React.ReactNode
  className?: string
}

export function Layout({ children, className }: LayoutProps) {
  const animate = useAnimate()

  return (
    <main css={rootCss}>
      {/* background elements */}
      <img
        src={decor01}
        aria-hidden
        css={theme => [decor01Css(theme), animate.entry(0, { duration: 50 })]}
        alt=""
      />
      <img
        src={decor02}
        aria-hidden
        css={theme => [decor02Css(theme), animate.entry(0, { duration: 50 })]}
        alt=""
      />
      {/* end of background elements */}

      <div css={childrenCss} className={className}>
        {children}
      </div>
    </main>
  )
}

/* styles */

const rootCss: ThemeCss = theme => ({
  backgroundColor: theme.colors.secondaryBackground,
  position: `relative`,
  display: `flex`,
  alignItems: `center`,
  minHeight: `100vh`,
  width: `100%`,
  justifyContent: `center`,
  padding: `10% ${theme.space[5]} 15%`,

  "& > img": {
    position: `absolute`,
  },
})

const decor01Css: ThemeCss = _theme => ({
  bottom: `${randomNumber(10, 70)}%`,

  left: 0,
})

const decor02Css: ThemeCss = _theme => ({
  top: `${randomNumber(10, 60)}%`,

  right: 0,
})

const childrenCss: ThemeCss = theme => ({
  display: `grid`,
  position: `relative`,
})

function randomNumber(min: number, max: number) {
  // @SONAR_STOP@
  return Math.random() * (max - min) + min
  // @SONAR_START@
}
