import * as React from "react"
import Loading from "@modules/ui/components/Loading"
import {
  AuthenticationOption,
  AuthenticationLabels,
  AuthenticationOptions,
  AuthenticationIcons,
} from "../constants/authentication"
import { auth as text } from "@modules/locales/default.js"
import {
  FormattedMessage,
  commonMessageTags,
  interpolateMessage,
} from "@modules/locales"
import {
  Link,
  Text,
  Spacer,
  ThemeCss,
  Button,
  Card,
  CardDivider,
  Heading,
} from "gatsby-interface"
import { StatusPageBanner } from "@modules/status/components/StatusPageBanner"
import { useAnimate } from "@modules/ui/hooks/useAnimate"
import { boxCss } from "@modules/user/shared/stylesheets/box"
import { AuthError, useAuthError } from "./AuthError"

export type AuthenticationProps = {
  onOptionClick: (option: AuthenticationOption) => void
  context?: `login` | `signup`
  className?: string
  paramsString: string
}

export function Authentication({
  onOptionClick,
  context = `login`,
  className,
  paramsString,
}: AuthenticationProps) {
  const [isAuthenticating, setIsAuthenticating] = React.useState(false)
  const animate = useAnimate()
  const isSignup = context === `signup`
  const error = useAuthError()

  const handleClick = (option: AuthenticationOption) => {
    setIsAuthenticating(true)
    onOptionClick(option)
  }

  return (
    <Card css={boxCss} className={className} as="div">
      <Heading as="h1" css={animate.entry(50)}>
        <FormattedMessage<never, "strong">
          message={text.headers[isSignup ? `signUpFor` : `logInTo`]}
          tags={commonMessageTags}
        />
      </Heading>

      <StatusPageBanner css={statusPageBannerCss} />

      {isAuthenticating && (
        <Loading
          bufferSize="none"
          css={loadingCss(isSignup)}
          message="Authenticating..."
        />
      )}

      {error && <AuthError message={error.message} />}

      {!isAuthenticating && (
        <div
          css={theme => [
            optionsCss(theme),
            animate.entry(50, { duration: 500 }),
          ]}
        >
          {AuthenticationOptions.map(option => {
            const logo = AuthenticationIcons?.[option] || null

            return (
              <Button
                key={option}
                onClick={() => handleClick(option)}
                variant="SECONDARY"
                size="XL"
              >
                <span css={optionCss}>
                  {logo}
                  {interpolateMessage<"oauthProvider">(
                    text.actions.continueWith,
                    {
                      oauthProvider: AuthenticationLabels[option],
                    }
                  )}
                </span>
              </Button>
            )
          })}

          <Spacer size={2} />

          {!isSignup && (
            <Text size="L" noMarginBottom={true} css={animate.entry(150)}>
              <FormattedMessage<never, "link">
                message={text.messages.ssoLogin}
                tags={{
                  link: function(content) {
                    return (
                      <Link
                        to={`/dashboard/sso${paramsString}`}
                        variant="SIMPLE"
                      >
                        {content}
                      </Link>
                    )
                  },
                }}
              />
            </Text>
          )}
        </div>
      )}

      {isSignup && (
        <Text noMarginBottom={true} size="S" css={animate.entry(50)}>
          <FormattedMessage<never, "termsLink" | "privacyLink">
            message={text.messages.byClickingContinueYouAgree}
            tags={{
              termsLink: function(content) {
                return (
                  <Link to="/terms-and-conditions" variant="SIMPLE">
                    {content}
                  </Link>
                )
              },
              privacyLink: function(content) {
                return (
                  <Link to="/privacy-policy" variant="SIMPLE">
                    {content}
                  </Link>
                )
              },
            }}
          />
        </Text>
      )}

      <CardDivider css={animate.entry(50, { duration: 2000 })} />

      <Text
        noMarginBottom={true}
        size="S"
        noMarginTop={true}
        css={animate.entry(50)}
      >
        <FormattedMessage<never, "link">
          message={
            isSignup ? text.messages.haveAnAccount : text.messages.noAccount
          }
          tags={{
            link: function(content) {
              return (
                <Link
                  to={isSignup ? `/dashboard/login` : `/dashboard/signup`}
                  variant="SIMPLE"
                >
                  {content}
                </Link>
              )
            },
          }}
        />
      </Text>
    </Card>
  )
}

/* styles  */

const statusPageBannerCss: ThemeCss = _theme => ({
  flexWrap: `wrap`,
  justifyContent: `center`,

  "& > *": {
    margin: 0,
  },
})

const optionsCss: ThemeCss = theme => ({
  display: `grid`,
  gridGap: theme.space[4],
  textAlign: `center`,
})

const optionCss: ThemeCss = _theme => ({
  minWidth: `14rem`,
  display: `flex`,
  alignItems: `center`,
})

const loadingCss = (isSignup: boolean) => ({
  marginTop: isSignup ? `2.5rem` : `3.7rem`,
  marginBottom: isSignup ? `2.5rem` : `3.7rem`,
})
