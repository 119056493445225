import * as React from "react"
import { ThemeCss, CheckIcon, AnchorButton } from "gatsby-interface"
import {
  TierType,
  SupportedPricingTier,
  supportedTiers,
  RichFeature,
} from "../constants/tiers"
import capitalizeStr from "@modules/toolkit/helpers/capitalizeStr"
import { MdArrowUpward } from "react-icons/md"
import { BillingInterval } from "@modules/graphql/types"

const headerCss: ThemeCss = theme => ({
  display: `flex`,
  alignItems: `center`,
  flexWrap: `wrap`,
})

const priceCss: ThemeCss = theme => ({
  marginLeft: theme.space[3],

  [theme.mediaQueries.desktop]: {
    marginLeft: theme.space[10],
  },
})

const wrapperCss: ThemeCss = theme => ({
  display: `flex`,
  marginTop: theme.space[2],

  [theme.mediaQueries.desktop]: {
    width: `auto`,
    marginTop: 0,
  },
})

const labelCss: ThemeCss = theme => ({
  marginRight: theme.space[3],
})

const nameCss: ThemeCss = theme => ({
  whiteSpace: `nowrap`,
  display: `flex`,
  alignItems: `center`,

  svg: {
    marginRight: theme.space[3],
  },
})

const featuresList: ThemeCss = theme => ({
  listStyle: `none`,
  padding: 0,
  margin: 0,
  fontSize: theme.fontSizes[2],
  lineHeight: theme.lineHeights.default,
  color: theme.colors.grey[90],
  display: `grid`,
  marginTop: theme.space[4],
})

const featureItemCss: ThemeCss = theme => ({
  display: `flex`,
  alignItems: `center`,
  fontSize: theme.fontSizes[2],
  margin: `${theme.space[1]} 0`,
  color: theme.colors.grey[70],
})

const featureIconBox: ThemeCss = theme => ({
  width: `24px`,
  height: `24px`,
  display: `inline-flex`,
  alignItems: `center`,
  justifyContent: `center`,
  marginRight: theme.space[3],
})

const featureIconCss: ThemeCss = theme => ({
  color: theme.colors.grey[60],
})

const checkIconCss: ThemeCss = theme => ({
  color: theme.colors.green[60],
})

const chooseBtnCss: ThemeCss = theme => ({
  marginLeft: theme.space[3],
})

export type NewPlanTierInfoProps = {
  billingInterval?: BillingInterval
  tier?: SupportedPricingTier
  showHeader?: boolean
  showDetails?: boolean
  showTip?: boolean
  showPrice?: boolean
}
export type PlanTierInfoProps = {
  tierType: TierType
  billingInterval?: BillingInterval
  tier?: SupportedPricingTier
  showHeader?: boolean
  showDetails?: boolean
  showTip?: boolean
  showPrice?: boolean
}

export function NewPlanTierInfo({
  tier,
  billingInterval = BillingInterval.Annual,
  showHeader = true,
  showDetails = true,
  showTip = false,
  showPrice = false,
  ...rest
}: NewPlanTierInfoProps) {
  const tierDetails = tier && supportedTiers[tier]

  if (!tierDetails && showTip) {
    return (
      <span css={headerCss}>
        <AnchorButton
          href={`#planTierSelector`}
          rightIcon={<MdArrowUpward />}
          size="M"
          variant="SECONDARY"
          target="_self"
          css={chooseBtnCss}
        >
          Choose Plan
        </AnchorButton>
      </span>
    )
  }

  if (!tierDetails) {
    return null
  }

  const {
    planFeatures: features,
    planIcon: Icon,
    planMonthlyAmount: monthlyAmount,
  } = tierDetails
  const price = monthlyAmount && monthlyAmount[billingInterval]
  const finalPrice = price && price / 100

  return (
    <div {...rest}>
      {showHeader && (
        <span css={headerCss}>
          <div css={wrapperCss}>
            <span
              css={theme => [
                nameCss(theme),
                {
                  color: tierDetails.color,
                },
              ]}
            >
              {Icon && <Icon />} <strong>{tierDetails.name}</strong>
            </span>
            {showPrice && (
              <span css={priceCss}>
                <b>${finalPrice}</b>/month
              </span>
            )}
          </div>
        </span>
      )}

      {/* todo: bring main features of the tier */}
      {showDetails && features && (
        <ul css={featuresList}>
          {features.map((feature: RichFeature, idx: number) => {
            const { label, icon: Icon, negative } = feature

            return (
              <li key={idx} css={featureItemCss}>
                <span css={featureIconBox}>
                  {Icon ? (
                    <Icon aria-hidden size="xsmall" css={featureIconCss} />
                  ) : (
                    <CheckIcon css={checkIconCss} size="small" />
                  )}
                </span>{" "}
                <span
                  css={
                    negative && {
                      textDecoration: `line-through`,
                    }
                  }
                >
                  {label}
                </span>
              </li>
            )
          })}
        </ul>
      )}
    </div>
  )
}
export function PlanTierInfo({
  tierType,
  tier,
  billingInterval = BillingInterval.Annual,
  showHeader = true,
  showDetails = true,
  showTip = false,
  showPrice = false,
  ...rest
}: PlanTierInfoProps) {
  const tierTypeFormated = capitalizeStr({ str: tierType })
  const tierDetails = tier && supportedTiers[tier]

  if (!tierDetails && showTip) {
    return (
      <span css={headerCss}>
        <span css={labelCss}>{tierTypeFormated}:</span>
        <AnchorButton
          href={`#${tierType}TierSelector`}
          rightIcon={<MdArrowUpward />}
          size="M"
          variant="SECONDARY"
          target="_self"
          css={chooseBtnCss}
        >
          Choose Plan
        </AnchorButton>
      </span>
    )
  }

  if (!tierDetails) {
    return null
  }

  const features =
    tierType === `builds`
      ? tierDetails.buildsFeatures
      : tierDetails.hostingFeatures

  const Icon =
    tierType === `builds` ? tierDetails.buildsIcon : tierDetails.hostingIcon
  const monthlyAmount =
    tierType === `builds`
      ? tierDetails.buildsMonthlyAmount
      : tierDetails.hostingMonthlyAmount
  const price = monthlyAmount && monthlyAmount[billingInterval]
  const finalPrice = price && price / 100

  return (
    <div {...rest}>
      {showHeader && (
        <span css={headerCss}>
          <span css={labelCss}>{tierTypeFormated}:</span>
          <div css={wrapperCss}>
            <span
              css={theme => [
                nameCss(theme),
                {
                  color: tierDetails.color,
                },
              ]}
            >
              {Icon && <Icon />} <strong>{tierDetails.name}</strong>
            </span>
            {showPrice && (
              <span css={priceCss}>
                <b>${finalPrice}</b>/month
              </span>
            )}
          </div>
        </span>
      )}

      {/* todo: bring main features of the tier */}
      {showDetails && features && (
        <ul css={featuresList}>
          {features.map((feature: RichFeature, idx: number) => {
            const { label, icon: Icon, negative } = feature

            return (
              <li key={idx} css={featureItemCss}>
                <span css={featureIconBox}>
                  {Icon ? (
                    <Icon aria-hidden size="xsmall" css={featureIconCss} />
                  ) : (
                    <CheckIcon css={checkIconCss} size="small" />
                  )}
                </span>{" "}
                <span
                  css={
                    negative && {
                      textDecoration: `line-through`,
                    }
                  }
                >
                  {label}
                </span>
              </li>
            )
          })}
        </ul>
      )}
    </div>
  )
}
