import * as React from "react"
import { Button, ThemeCss, Spacer, Link, Text } from "gatsby-interface"
import {
  BillingInterval,
  MachinePricingTier,
  CloudRole,
  SubscriptionStatus,
} from "@modules/graphql/types"
import { WizardStepHeader } from "@modules/ui/components/WizardStepHeader"
import { WizardFooter } from "@modules/ui/components/WizardFooter"
import { MdArrowForward } from "react-icons/md"
import { useFilteredPlans } from "@modules/billing/components/Billing.helpers"
import {
  createOrganization as createOrgText,
  ui as uiText,
} from "@modules/locales/default.js"
import { FormattedMessage } from "@modules/locales"

import { useUserOrganizationsWithBillingQuery } from "@modules/user-settings/queries.generated"
import {
  NewMultiPlanSelector,
  PricingTier,
} from "@modules/billing/shared/components/newMultiPlanSelector/NewMultiPlanSelector"
import {
  PlanInfo,
  SupportedPricingTier,
} from "@modules/billing/shared/constants/tiers"
import { NewPlanTierInfo } from "@modules/billing/shared/components/PlanTierInfo"
import { getPathToOrgDetails } from "@modules/organization/shared/utils"
import { StandardSingleColumn } from "@modules/ui/layouts/Containers"
import { useLocation } from "@gatsbyjs/reach-router"
import * as qs from "query-string"
import { PlanSelector } from "@modules/billing/shared/components/PlanSelector"
import { useFlags } from "@modules/featureFlags"
import { useAvailableBillingPlans } from "@modules/billing/shared/hooks/useAvailableBillingPlans"

const selectorCss: ThemeCss = theme => ({
  marginBottom: theme.space[10],
})

const selectionPreviewCss: ThemeCss = theme => ({
  display: `flex`,
  justifyContent: `center`,
  columnGap: theme.space[5],
  paddingTop: theme.space[8],
  marginBottom: `-${theme.space[12]}`,
  flexDirection: `column`,
  alignItems: `center`,

  [theme.mediaQueries.desktop]: {
    flexDirection: `row`,
  },
})

export type PickOrganizationPlanProps = {
  selectedPlanId: string | null
  selectedPlanName: string | null
  selectedWorkspaceName: string | null
  selectedBillingInterval: BillingInterval
  onGoNext: (
    planInfo: { [P in keyof PlanInfo]: Exclude<PlanInfo[P], null> }
  ) => void
  onGoBack: () => void
}

export function PickOrganizationPlan({
  selectedPlanId,
  selectedPlanName,
  selectedWorkspaceName,
  onGoNext,
  onGoBack,
}: PickOrganizationPlanProps) {
  const { flags } = useFlags()
  const { search } = useLocation()
  const { siteTransfer } = qs.parse(search)

  const [planInfo, setPlanInfo] = React.useState<PlanInfo>({
    planId: selectedPlanId,
    planName: selectedPlanName,
    billingInterval: BillingInterval.Annual,
    tier: null,
  })

  const filteredPlans = useFilteredPlans({
    interval: planInfo.billingInterval,
    skip: flags.pricingFall2022,
  })

  const billingPlans = useAvailableBillingPlans({
    skip: !flags.pricingFall2022,
  })

  const userOrganizations = useUserOrganizationsWithBillingQuery()
  const currentOrganizations =
    (userOrganizations?.data && userOrganizations.data.currentOrganizations) ||
    []

  if (userOrganizations.loading) {
    return null
  }

  // finds a workspace with a FREE or TRIALING plan for which the user is an OWNER
  const freeOrTrialingWorkspaceBelongingToUser = currentOrganizations.find(
    item =>
      (item.billing?.plan?.name === `Free` ||
        item.billing?.status === SubscriptionStatus.Trialing) &&
      item.permissions?.role === CloudRole.Owner
  )

  // translates for readability
  const userIsAlreadyOwnerOfFreeOrTrialingWorkspace = Boolean(
    freeOrTrialingWorkspaceBelongingToUser
  )

  // checks if creating a new FREE workspace is allowed or not for the user
  // only one who belongs to a Workspace on a Paid (not trialing) plan with at least Contributor role
  // should be able to create a Free workspace
  const userIsValidMemberOfPaidPlanWorkspace = currentOrganizations.some(
    item =>
      item.billing?.plan?.name !== `Free` &&
      item.billing?.status !== SubscriptionStatus.Trialing &&
      item.permissions?.role &&
      [CloudRole.Owner, CloudRole.Admin, CloudRole.Contributor].includes(
        item.permissions.role
      )
  )

  // disables Free plan card if the user is alread an owner of one (free/or trialing)
  // and is not a member (at least with Contributor role) of any of the paid plan workspace
  // or he creates a new workspace as a part of the Site Transfer flow
  const freeIsDisabled = Boolean(
    (userIsAlreadyOwnerOfFreeOrTrialingWorkspace &&
      !userIsValidMemberOfPaidPlanWorkspace) ||
      siteTransfer
  )

  /**
   * We want to automatically select a plan in one of these cases:
   *  - the user gets to this step for the first time (they have not selected a plan yet),
   *    in which case we select either the suggested plan ("Professional")
   *    or the first available plan if the suggested one cannot be found
   *  - the user changes the billing interval, in which case we select the plan with the same name
   */

  if (filteredPlans.loading && filteredPlans.plans.length === 0) {
    return null
  }

  return (
    <form
      onSubmit={e => {
        e.preventDefault()
        onGoNext({
          planId: planInfo.planId as string,
          planName: planInfo.planName as string,
          billingInterval: planInfo.billingInterval,
        })
      }}
    >
      <div
        css={{
          display: `flex`,
          flexDirection: `column`,
          alignItems: `center`,
        }}
      >
        <WizardStepHeader title={`Pick a plan for ${selectedWorkspaceName}`}>
          {freeIsDisabled && (
            <FormattedMessage<"workspaceName", "link">
              message={createOrgText.messages.alreadyHasFreeWorkspace}
              values={{
                workspaceName:
                  freeOrTrialingWorkspaceBelongingToUser?.name ||
                  "your workspace",
              }}
              tags={{
                link: function(linkText) {
                  return (
                    <Link
                      to={getPathToOrgDetails(
                        freeOrTrialingWorkspaceBelongingToUser?.id
                      )}
                    >
                      {linkText}
                    </Link>
                  )
                },
              }}
            />
          )}
        </WizardStepHeader>
        <StandardSingleColumn>
          {flags.pricingFall2022 ? (
            <PlanSelector
              selectedPlan={planInfo}
              onChange={setPlanInfo}
              billingPlans={billingPlans.plans}
              freeIsUnavailable={freeIsDisabled}
              css={selectorCss}
            />
          ) : (
            <NewMultiPlanSelector
              planInfo={planInfo}
              setPlanInfo={setPlanInfo}
              availablePlans={filteredPlans.plans}
              diyTiersOnly={true}
              disabledTiers={freeIsDisabled ? [PricingTier.Free] : []}
              css={selectorCss}
            />
          )}
        </StandardSingleColumn>
        <Text>
          <FormattedMessage<never, "link">
            message={createOrgText.messages.customPlan}
            tags={{
              link: function(linkText) {
                return <Link to="/contact-sales">{linkText}</Link>
              },
            }}
          />
        </Text>
      </div>
      <WizardFooter
        goBackButton={
          <Button variant="SECONDARY" onClick={() => onGoBack()}>
            {uiText.actions.back}
          </Button>
        }
        goNextButton={
          <Button
            type="submit"
            disabled={!planInfo.tier}
            rightIcon={<MdArrowForward />}
          >
            {uiText.actions.next}
          </Button>
        }
      >
        <div css={selectionPreviewCss}>
          <NewPlanTierInfo
            tier={planInfo.tier as SupportedPricingTier}
            showTip={true}
            showDetails={false}
            billingInterval={planInfo.billingInterval}
          />

          {planInfo.tier !== MachinePricingTier.Free && (
            <React.Fragment>
              <span> · </span>
              <span>
                Billed{" "}
                {planInfo.billingInterval === BillingInterval.Monthly
                  ? "monthly"
                  : "yearly"}
              </span>
            </React.Fragment>
          )}
        </div>
      </WizardFooter>
    </form>
  )
}
