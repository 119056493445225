import * as React from "react"
import { ThemeCss, Heading } from "gatsby-interface"
import { Features } from "./Features"
import { data } from "../constants/features"
import { useAnimate } from "@modules/ui/hooks/useAnimate"
import { HomeLink } from "@modules/user/shared/components/HomeLink"

export function Promotion() {
  const animate = useAnimate()

  return (
    <div css={rootCss}>
      <HomeLink />

      <Heading as="h1" css={theme => [headingCss(theme), animate.entry()]}>
        The best way to <strong>build, deploy & scale Gatsby sites</strong>
      </Heading>

      <Features data={data} />
    </div>
  )
}

/* styles */

const rootCss: ThemeCss = theme => ({
  display: `grid`,
  width: `100%`,
  justifyItems: `start`,
  gap: theme.space[9],

  [theme.mediaQueries.desktop]: {
    width: `20rem`,
  },
})

const headingCss: ThemeCss = theme => ({
  fontSize: theme.fontSizes[7],

  strong: {
    color: theme.colors.gatsby,
    display: `block`,
  },
})
