import { keyframes } from "@emotion/core"
import { useFlags } from "@modules/featureFlags"

const DEFAULT_UNIT = `ms`
const DEFAULT_DURATION = 200

const entry = keyframes`
  to {
    opacity: 1;
  }
`

export type Options = {
  duration?: number
  unit?: `s` | `ms`
}

export function useAnimate() {
  const { flags } = useFlags()

  if (!flags.microAnimations) {
    return {
      entry: () => undefined,
    }
  }

  const animateEntry = (delay = 0, options: Options = {}) => {
    const { unit = DEFAULT_UNIT, duration = DEFAULT_DURATION } = options || {}

    return {
      "@media (prefers-reduced-motion: no-preference)": {
        animation: `${entry} ${duration}${unit} ease forwards`,
        animationDelay: `${delay}${unit}`,
        opacity: 0,
      },
    }
  }

  return {
    entry: animateEntry,
  }
}
