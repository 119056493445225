import * as React from "react"
import { MdArrowForward } from "react-icons/md"
import { ThemeCss, Link, Button, Card, Theme, Badge } from "gatsby-interface"
import {
  BuildStatus,
  BuildCommit,
  BuildRunnerType,
  BuildType,
  User,
  BuildSource,
  BuildRouteMetadata,
  BuildMetadata,
} from "@modules/graphql/types"

import { visuallyHiddenCss } from "@modules/a11y/stylesheets"
import BuildStatusIndicator from "@modules/build/shared/components/BuildStatusIndicator"
import { BuildEventText } from "@modules/build/shared/components/BuildEventText"
import BuildAuthor from "./BuildAuthor"
import BuildCardStatusInfo from "./BuildCardStatusInfo"
import useBuildChangedSubscription from "@modules/build/shared/hooks/useBuildChangedSubscription"
import { usePullRequestChangedSubscription } from "@modules/build/shared/hooks/usePullRequestChangedSubscription"
import { DeploymentStatus } from "./DeploymentStatus"
import { DeploymentTiming } from "./DeploymentTiming"
import { isIncrementalUpdate } from "@modules/build/shared/utils"
import IncrementalBuildChip from "@modules/build/shared/components/IncrementalBuildChip"
import {
  deploysView as deploysViewText,
  build as buildText,
} from "@modules/locales/default.js"
import { getContextuallyFormatedDate } from "@modules/ui/utils/getContextuallyFormatedDate"
import { LighthouseScores } from "@modules/metrics/components/LighthouseScores"
import BuildCommitInfo from "@modules/build/shared/components/BuildCommitInfo"
import { FiUploadCloud } from "react-icons/fi"
import { AlphaIcon } from "../../shared/components/AlphaIcon"
import { BurstIcon } from "@modules/build/shared/components/BurstIcon"
import { InfinityIcon } from "../../shared/components/InfinityIcon"
import { useTracker, SegmentEventType } from "@modules/analytics"
import { LayersIcon } from "gatsby-ui/src/components/icons"

import {
  rowCss,
  itemCss,
  actionsCss,
  separatorCss,
} from "@modules/build/card/stylesheets"
import { useFlags } from "@modules/featureFlags"

export type BuildCardProps = {
  id: string
  siteId: string
  organizationId: string
  buildId: string
  status: BuildStatus
  branch: string
  runnerType: BuildRunnerType
  buildType: BuildType
  isProductionBranch: boolean
  viewDetailsHref: string
  viewAllBuildsHref?: string
  repositoryUrl?: string
  createdAt: string
  title?: string
  pullRequestId?: string
  commit?: BuildCommit
  author?: Partial<User>
  source?: BuildSource
  duration?: number
  startedAt?: string // coming as ISOstring
  endedAt?: string // coming as ISOstring
  deployStartedAt?: string
  deployEndedAt?: string
  onBuildSucceed?: () => void
  onPublish?: (id: string) => void
  latestHostingDeployVersion?: string
  manualHostingDeploysEnabled?: boolean
  gatsbyHostingOn?: boolean
  isEligiblePlan?: boolean
  dataTestid?: string
  as?: `div` | `article`
  routeMetadata?: BuildRouteMetadata
  buildMetadata?: BuildMetadata
  onViewBuildQueue?: (id: string) => void
  buildUrl?: string
}

export function BuildCard({
  siteId,
  organizationId,
  buildId,
  pullRequestId,
  status,
  startedAt,
  createdAt,
  duration,
  endedAt,
  branch,
  commit,
  runnerType,
  buildType,
  author,
  source,
  viewDetailsHref,
  viewAllBuildsHref,
  deployStartedAt,
  deployEndedAt,
  onBuildSucceed,
  repositoryUrl,
  latestHostingDeployVersion,
  manualHostingDeploysEnabled,
  onPublish,
  gatsbyHostingOn,
  isEligiblePlan,
  dataTestid,
  as,
  routeMetadata,
  buildMetadata,
  onViewBuildQueue,
  buildUrl,
}: BuildCardProps) {
  const { trackSegment, trackAction } = useTracker()
  const creationDate = getContextuallyFormatedDate(createdAt)
  const { flags } = useFlags()

  const hasDsgRoutes = routeMetadata && routeMetadata.dsgRouteCount > 0
  const hasSsrRoutes = routeMetadata && routeMetadata.ssrRouteCount > 0
  const hasFunctionRoutes =
    routeMetadata && routeMetadata.functionRouteCount > 0
  const isBurstBuild = buildMetadata?.burstMode

  useBuildChangedSubscription(buildId, runnerType, onBuildSucceed)
  usePullRequestChangedSubscription(pullRequestId)

  const isPublished = latestHostingDeployVersion === buildId
  const urlForBuild =
    buildUrl ?? `build-${buildId}${process.env.GATSBY_PREVIEW_DOMAIN}`
  const showDeployment =
    !gatsbyHostingOn || !isEligiblePlan
      ? true
      : isPublished ||
        (status === BuildStatus.Publishing && !manualHostingDeploysEnabled) ||
        status === BuildStatus.PublishCanceled ||
        status === BuildStatus.PublishError
      ? true
      : false

  const isSuccessfullyBuilt = [
    BuildStatus.Success,
    BuildStatus.PublishCanceled,
    BuildStatus.PublishError,
  ].includes(status)

  const isInQueue = [BuildStatus.Queued, BuildStatus.Building].includes(status)

  // accelerated sourcing
  const isAcceleratedSourcingEnabled =
    flags.enableMerlinPullRequestsSourcingToggle && buildMetadata?.merlinSourced

  return (
    <Card
      as={as}
      data-cy={buildId}
      data-testid={dataTestid}
      css={(theme: Theme) => [
        cardCss(theme),
        gatsbyHostingOn && isEligiblePlan && isPublished && publishedCss(theme),
      ]}
    >
      <div css={cardHeaderCss}>
        <div css={headerLeftCss}>
          {gatsbyHostingOn &&
            isEligiblePlan &&
            status === BuildStatus.Success &&
            isPublished && (
              <span css={publishedBadgeCss}>{buildText.labels.published}</span>
            )}
          <h3 css={titleCss}>
            <Link to={viewDetailsHref} variant="SIMPLE" css={titleLinkCss}>
              <BuildEventText
                commit={commit}
                buildType={buildType}
                buildSource={source}
                runnerType={runnerType}
              />
            </Link>
          </h3>
        </div>
        <div css={headerRightCss}>
          {viewAllBuildsHref && (
            <Link variant="SIMPLE" to={viewAllBuildsHref} css={headerLinkCss}>
              {deploysViewText.labels.history}
            </Link>
          )}

          {isSuccessfullyBuilt && (
            <Link
              href={`https://${urlForBuild}`}
              variant="SIMPLE"
              css={headerLinkCss}
            >
              {deploysViewText.actions.visitBuild}
            </Link>
          )}

          {isInQueue && onViewBuildQueue && (
            <Button
              variant="GHOST"
              onClick={() => {
                trackAction({
                  eventType: `TRACK_EVENT`,
                  name: `View Build Queue clicked`,
                  uiSource: `Build card`,
                })
                trackSegment({
                  type: SegmentEventType.Track,
                  event: `View Build Queue clicked`,
                  properties: {
                    location: `Build card`,
                  },
                })
                onViewBuildQueue?.(buildId)
              }}
              css={headerLinkCss}
              size="S"
              rightIcon={<MdArrowForward />}
            >
              {deploysViewText.actions.viewBuildQueue}
            </Button>
          )}
        </div>
      </div>

      <div css={rowsCss}>
        <div css={rowCss}>
          <div css={itemCss} data-cy="deploy-build-status">
            <BuildStatusIndicator
              id={`deploy-status-${buildId}`}
              a11yId={buildId}
              buildStatus={status}
              runnerType={runnerType}
              branch={branch}
            />
            <BuildCardStatusInfo
              createdAt={createdAt}
              buildStatus={status}
              startedAt={startedAt}
              endedAt={endedAt}
              duration={duration}
            />
          </div>

          {deployStartedAt && showDeployment && (
            <React.Fragment>
              <span css={separatorCss}>›</span>
              <div css={itemCss}>
                <DeploymentStatus value={status} />

                <DeploymentTiming
                  status={status}
                  startDate={new Date(deployStartedAt)}
                  endDate={deployEndedAt ? new Date(deployEndedAt) : undefined}
                  vendor={""}
                />
              </div>
            </React.Fragment>
          )}

          {isSuccessfullyBuilt && (
            <div css={badgesCss}>
              {flags.enableBurstModeToggle && isBurstBuild && (
                <Badge
                  css={burstBadgeCss}
                  tone="NEUTRAL"
                  textVariant="DEFAULT"
                  Icon={BurstIcon}
                >
                  {buildText.labels.burst}
                </Badge>
              )}

              {hasDsgRoutes && (
                <Badge tone="NEUTRAL" textVariant="DEFAULT">
                  {buildText.labels.dsg}
                </Badge>
              )}

              {hasSsrRoutes && (
                <Badge tone="NEUTRAL" textVariant="DEFAULT" Icon={InfinityIcon}>
                  {buildText.labels.ssr}
                </Badge>
              )}

              {hasFunctionRoutes && (
                <Badge tone="NEUTRAL" textVariant="DEFAULT" Icon={AlphaIcon}>
                  {buildText.labels.func}
                </Badge>
              )}

              {isAcceleratedSourcingEnabled && (
                <Badge tone="NEUTRAL" textVariant="DEFAULT" Icon={LayersIcon}>
                  Content Hub
                </Badge>
              )}
            </div>
          )}

          <div css={actionsCss}>
            {status === BuildStatus.Error && (
              <Link
                to={`${viewDetailsHref}#errors`}
                variant="SIMPLE"
                css={errorCss}
              >
                {deploysViewText.actions.viewErrors}
              </Link>
            )}

            {gatsbyHostingOn &&
              isEligiblePlan &&
              isSuccessfullyBuilt &&
              !isPublished && (
                <Button
                  size="M"
                  variant="SECONDARY"
                  onClick={onPublish ? () => onPublish(buildId) : undefined}
                  rightIcon={
                    <FiUploadCloud
                      css={_ => ({
                        width: `16px`,
                        height: `auto`,
                      })}
                    />
                  }
                >
                  {buildText.actions.publish}
                </Button>
              )}
          </div>
        </div>

        <div css={rowCss}>
          <BuildAuthor
            buildType={buildType}
            commit={commit}
            author={author ? author : undefined}
            source={source}
          />

          <div css={itemCss}>
            <span css={visuallyHiddenCss}>
              {deploysViewText.messages.buildTriggered}
            </span>
            <span>{creationDate}</span>
          </div>

          <BuildCommitInfo
            branch={branch}
            commit={commit}
            repositoryUrl={repositoryUrl || ""}
            withLink={true}
            withIcon={true}
          />

          {isIncrementalUpdate(runnerType, buildType) && (
            <IncrementalBuildChip />
          )}
        </div>

        {[
          BuildStatus.Success,
          BuildStatus.Publishing,
          BuildStatus.PublishCanceled,
          BuildStatus.PublishError,
        ].includes(status) && (
          <div css={rowCss}>
            <LighthouseScores
              siteId={siteId}
              organizationId={organizationId}
              buildId={buildId}
              branch={branch}
            />
          </div>
        )}
      </div>
    </Card>
  )
}

/* styles */

const cardCss: ThemeCss = theme => ({
  padding: `${theme.space[6]} ${theme.space[6]} ${theme.space[4]}`,

  [theme.mediaQueries.tablet]: {
    padding: `${theme.space[7]} ${theme.space[7]} ${theme.space[5]}`,
  },
})

const cardHeaderCss: ThemeCss = theme => ({
  display: "flex",
  justifyContent: "space-between",
  gap: theme.space[2],
  flexDirection: "column",

  [theme.mediaQueries.tablet]: {
    gap: theme.space[4],
    flexDirection: "row",
    alignItems: "center",
  },
})

const titleCss: ThemeCss = theme => ({
  margin: 0,
  fontSize: theme.fontSizes[2],
  fontWeight: theme.fontWeights.semiBold,
  fontFamily: theme.fonts.body,
  display: `flex`,
  justifyContent: `flex-start`,
  marginRight: `auto`,
})

const publishedBadgeCss: ThemeCss = theme => ({
  padding: `6px ${theme.space[3]}`,
  textTransform: `uppercase`,
  letterSpacing: `1px`,
  lineHeight: theme.space[4],
  fontSize: theme.fontSizes[0],
  fontWeight: theme.fontWeights.semiBold,
  color: theme.colors.green[80],
  background: theme.colors.green[5],
  border: `1px solid ${theme.colors.green[10]}`,
  borderRadius: theme.radii[1],
})

const headerLeftCss: ThemeCss = theme => ({
  display: `flex`,
  alignItems: `center`,
  justifyContent: `flex-start`,
  gap: theme.space[4],
})

const headerRightCss: ThemeCss = theme => ({
  display: `flex`,
  flexDirection: `row`,
  alignItems: `center`,
  justifyContent: "flex-start",
  [theme.mediaQueries.tablet]: {
    justifyContent: `flex-end`,
    gap: theme.space[5],
  },
})

const titleLinkCss: ThemeCss = () => ({
  display: `block`,
  overflow: `hidden`,
  textOverflow: `ellipsis`,
})

const headerLinkCss: ThemeCss = theme => ({
  fontSize: theme.fontSizes[1],
})

const publishedCss: ThemeCss = theme => ({
  borderLeft: `4px solid ${theme.colors.green[50]}`,
  paddingLeft: `calc(${theme.space[7]} - 4px)`,
})

const rowsCss: ThemeCss = theme => ({
  marginTop: theme.space[4],
})

const errorCss: ThemeCss = theme => ({
  color: theme.colors.red[70],

  "&:hover": {
    color: theme.colors.red[40],
  },
})

const badgesCss: ThemeCss = theme => ({
  display: `flex`,
  flexWrap: "wrap",

  "& > *": {
    marginRight: theme.space[3],
  },
})

const burstBadgeCss: ThemeCss = _theme => ({
  border: "1px solid #DBF0FF",
  background: "#F5FCFF",
})
