import * as React from "react"
import { ThemeCss, Heading, Text } from "gatsby-interface"
import { useAnimate } from "@modules/ui/hooks/useAnimate"
import { Feature } from "../constants/features"

export type FeaturesProps = {
  data: Feature[]
}

export function Features({ data }: FeaturesProps) {
  const animate = useAnimate()

  return (
    <ul css={rootCss}>
      {data.map((item, idx) => {
        const { heading, description, icon } = item

        return (
          <li
            key={idx}
            css={theme => [itemCss(theme), animate.entry(idx * 25)]}
          >
            <span css={iconCss}>
              <img src={icon} alt="" />
            </span>
            <Heading as="h3">{heading}</Heading>
            <Text size="S">{description}</Text>
          </li>
        )
      })}
    </ul>
  )
}

/* styles */

const rootCss: ThemeCss = theme => ({
  listStyle: `none`,
  margin: 0,
  display: `grid`,
  gap: theme.space[5],
})

const itemCss: ThemeCss = theme => ({
  display: `grid`,
  columnGap: theme.space[3],
  rowGap: theme.space[2],
  gridTemplateColumns: `${theme.space[7]} 1fr`,

  "& > img": {
    width: theme.space[8],
    size: theme.space[8],
  },

  "& > h3": {
    fontSize: theme.fontSizes[2],
  },

  "& > p": {
    color: theme.colors.grey[50],
  },
})

const iconCss: ThemeCss = theme => ({
  gridRow: `1 / 3`,
})
